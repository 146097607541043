<template>
  <div class="row">
    <div class="md-12">
      <div class="input-group">
        <span class="input-group-text">顯示樣式</span>
        <div class="input-group-append form-control">
          <label
            ><input
              type="radio"
              name="optradio"
              value="v"
              v-model="basedata.showstyle"
            />直式</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="h"
              v-model="basedata.showstyle"
            />橫式</label
          >
        </div>
      </div>
    </div>
    <div class="md-12">
      <div class="input-group">
        <span class="input-group-text">字形大小</span>
        <div class="input-group-append form-control">
          <label
            ><input
              type="radio"
              name="optradio"
              value="6"
              v-model="basedata.fontsize"
            />1</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="5"
              v-model="basedata.fontsize"
            />2</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="4"
              v-model="basedata.fontsize"
            />3</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="3"
              v-model="basedata.fontsize"
            />4</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="2"
              v-model="basedata.fontsize"
            />5</label
          >
          <label
            ><input
              type="radio"
              name="optradio"
              value="1"
              v-model="basedata.fontsize"
            />6</label
          >
        </div>
      </div>
    </div>
  </div>
  <div v-if="basedata.showstyle == 'v'">
    <div :class="'text-left display-' + basedata.fontsize">
      <span v-for="(n, index) in basedata.arrayWord_01U" :key="index">
        {{ n }}
      </span>
    </div>
  </div>
  <div
    class="d-flex flex-row-reverse"
    style="height: 100px"
    v-if="basedata.showstyle == 'h'"
  >
    <div class="d-inline-flex flex-column">
      <div v-for="(n, index) in basedata.arrayWord_01S" :key="index">
        {{ n }}
      </div>
    </div>
    <div class="d-inline-flex flex-column">
      <div v-for="(n, index) in basedata.arrayWord_01" :key="index">
        {{ n }}
      </div>
    </div>
    <div class="d-inline-flex flex-column">
      <div v-for="(n, index) in basedata.arrayWord_02S" :key="index">
        {{ n }}
      </div>
    </div>
    <div class="d-inline-flex flex-column">
      <div v-for="(n, index) in basedata.arrayWord_02" :key="index">
        {{ n }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
export default {
  setup() {
    const basedata = reactive({
      showstyle: "v" /*v or h*/,
      fontsize: "6",
      arrayWord_01: ref(
        "　　下至唯從聞一偈頌雖犯戒等亦應就其功德思惟莫觀過失悉無差別寶雲經云".split(
          ""
        )
      ),
      arrayWord_01S: ref(
        "　　　　　　　　　，　　　，　　　　　　　，　　　，　　　。　　　，".split(
          ""
        )
      ),
      arrayWord_01U: ref(
        "　　下至唯從聞一偈頌，雖犯戒等，亦應就其功德思惟莫觀過失，悉無差別寶雲經云".split(
          ""
        )
      ),
      arrayWord_02: ref(
        "﹁若知由其依止尊重諸善增長不善損減則親教師或聞廣博或復寡少或有智解或無".split(
          ""
        )
      ),

      arrayWord_02S: ref(
        "　　　　　　　　，　　　，　　　，　　　　　　　   ，      ".split("")
      ),
      arrayWord_02U: ref(
        "「若知由其依止尊重，諸善增長，不善損減，則親教師或聞廣博，或復寡少或有智解或無".split(
          ""
        )
      ),
    });

    console.log("arrayWord_01S:", basedata);

    basedata.arrayWord_01U = basedata.arrayWord_01U.concat(
      basedata.arrayWord_02U
    );

    return { basedata };
  },
};
</script>

<style>
.fill {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  background-color: lightblue;
}
</style>